import React from 'react';
import "../styles/cv.css"
import "../styles/resume-print.css"
import { useStaticQuery, graphql } from 'gatsby';

const Resume = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fileAbsolutePath: { regex: "//cv/headline/" }) {
        frontmatter {
          title
        }
      }
    }
  `);
  const headline = data.markdownRemark.frontmatter.title;
  return (
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
      <article className="h-resume">
        <article className="headline">
          <h1 className="">
            <a className="p-contact h-card" href="https://marcell.xyz/">
              <img
                className="u-photo"
                src="https://avatars.githubusercontent.com/u/4584144?s=400&u=fbe23e42bf8509c207e2bc80e31cff8d808f4d6e&v=4"
                alt="Marcell Cruz's profile photo"
              />
              <span className="">Marcell Cruz</span>
            </a>
          </h1>
          <p className="">
            Hi, I'm Marcell! I'm a passionate Senior Software Engineer with
            experience in front-end and back-end software development,
            interested in solving problems, sharing knowledge and enabling great
            customer outcomes. I strongly believe in building reusable software components with a high quality bar, and improving team
            ways of working so we can deliver better, and more effectively over time.
          </p>
          <p className="no-print">
            If you want to learn more about me, you may be interested in{" "}
            <ul>
              <li>
                <a href="https://marcell.xyz/">my website</a>
              </li>
              <li>
                <a href="https://dev.to/____marcell">blog posts</a>
              </li> 
              <li>
                <a href="https://www.youtube.com/@marcellmonteirocruz2475/videos">Youtube channel, not very active yet</a>
              </li>
            </ul>
          </p>
          <aside className="contact no-print">
            <p>You may also be interested in my other profiles:</p>
            <ul>
              <li title="github">
                <i className="fa fa-github" />{" "}
                <a
                  href="https://github.com/0000marcell"
                  className="u-url github"
                  rel="me self external"
                >
                  0000marcell on github 
                </a>
              </li>
              <li title="linkedin">
                <i className="fa fa-linkedin" />{" "}
                <a
                  href="https://www.linkedin.com/in/marcell-cruz-40313b87/"
                  className="u-url linkedin"
                  rel="me self external"
                >
                  Marcell on LinkedIn
                </a>
              </li>
            </ul>
          </aside>
          <p className="">
            Want to get in touch? Drop me an email at <i className="fa fa-envelope" />{" "}
            <a href="mailto:0000marcell@gmail.com" className="u-email">
              0000marcell@gmail.com
            </a>
            .
          </p>
          <p className="">
            A PDF copy of my CV can be generated by trying to{" "}
            <a href="#" onClick={() => window.print()}>
              print the page
            </a>{" "}
            (or using <kbd>Ctrl</kbd>-<kbd>P</kbd> / <kbd>Cmd</kbd>-<kbd>P</kbd>).
          </p>
        </article>
        <div>
          <h1>Experience</h1>
          <div className="p-experience h-event">
            <div className="line1">
              <span className="p-location h-card">
                <a className="p-org u-url" href="https://www.decisiv.com/">
                  Decisiv, Inc
                </a>
              </span>
              <span className="p-name">Senior Software Engineer</span>
              <div className="duration">
                <time className="dt-start" dateTime="2023-08-14T09:00:00+0100">
                  Fev 2021
                </time>{" "}
                <time className="dt-end">Present</time>
              </div>
              <p className="p-summary">
                <span>
                  Senior Software Engineer in a team of 5 across Brazil, US, Poland and Belarus on the Data Engineering Team.
                  Working towards delivering reports to clients, integrating third party data, pushing data to analytics databases and maintaining the data pipeline and ETL, using a variety of technologies, Python, Ruby, Rails, React, In memory databases like Redis, Amazon Redshift, SQS, lambda functions, RDS and much more, doing automated tests, CI/CD and TDD for multiple projects, including maintaining the company biggest software that has more than 15 years, before that in the same company I worked developing new products and features, again using Ruby on Rails, React, and a variety of other technologies, doing automated tests, CI/CD, microservices and serverless architecture, and also working with a variety of AWS services, such as Lambda, SQS, S3, RDS, and more, also working as a Tech Lead, helping other team member architecting and building new features and products.
                </span>
              </p>
              <ul className="p-achievement">
                <li className="">
                  Architect and build multiple feature and solutions 
                </li>
                <li className="">
                  Participate in big architectural decisions
                </li>
                <li className="">
                  Be invited to be a teach lead 
                </li>
                <li>
                  Build deploy and observability workflows 
                </li>
                <li>
                  Collaborate with engineers from all over of the world
                </li>
                <li>
                  Collaborate on new solutions together with clients and product managers 
                </li>
                <li className="">
                  Pairing with team members to solve complex problems, and also with Junior developers to help them grow and learn
                </li>
                <li>Working asynchronously with distributed teams and company</li>
              </ul>
            </div>
          </div>
          <div className="p-experience h-event">
            <div className="line1">
              <span className="p-location h-card">
                <a className="p-org u-url" href="https://www.original.io/">
                  Original.io
                </a>
              </span>
              <span className="p-name">Software Engineer</span>
              <div className="duration">
                <time className="dt-start" dateTime="2022-04-25T00:00:00Z">
                  April 2018
                </time>{" "}
                <time className="dt-end" dateTime="2023-07-21T18:00:00Z">
                  February 2021
                </time>
              </div>
              <p className="p-summary">
                Software Engineer in a team of 7 working on Developing a modern workflow for the company, CI/CD, modern build tools, automation, a CMS built with React/Flux/Node/Koa to control the underlying structure and enforce the workflows. 
I also played a role in hiring for the company, interviewing new candidates, and evaluating tests. Before that, I've helped build several e-commerce websites for large and small companies.
              </p>
              <ul className="p-achievement">
                <li>
                  Built modern frontend UI from scratch
                </li>
                <li>
                  Automated some repetitive tasks and workflows
                </li>
                <li>
                  Collaborated with engineers and designers to build new features
                </li>
                <li>
                  Built a tooling to provide a repeatable workflow for projects using Open Source
                  and internal technologies, allowing non technical people access to new off the shelf projects 
                </li>
                {/* ways of working */}
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h1>Education</h1>
          <div className="p-education h-event">
            <span className="p-name">Bachelor of Computer Science</span>,{" "}
            <span className="h-card">
              <a className="p-name u-url" href="https://www.uerj.br/">
                Rio de Janeiro State University
              </a>
            </span>
            ,<span className="dt-start">2014</span>
            <span className="dt-end">2018</span>
          </div>
        </div>
        <div className="skills">
          <h1>Skills</h1>
          <h3>Regularly Practice</h3>
          <ul className="">
            <li className="">Javascript</li>
            <li className="">Ruby</li>
            <li className="">React</li>
            <li className="">Ruby On Rails</li>
            <li className="">OpenAPI + designing HTTP APIs</li>
            <li className="">Docker</li>
            <li className="">Git</li>
            <li className="">Linux</li>
            <li className="">Building (internal) tools for automation</li>
            <li className="">Shell scripting</li>
            <li className="">CI/CD / DevOps Automation</li>
            <li className="">Technical writing</li>
            <li className="">Software testing</li>
            <li className="">OAuth2</li>
            <li className="">Coaching + mentoring</li>
            <li className="">Production / incident support</li>
            <li className="">Building reusable software</li>
            <li className="">Code review</li>
            <li className="">TypeScript</li>
            <li className="">GitHub Actions</li>
            <li className="">SQLite</li>
            <li className="">Postgres</li>
            <li className="">NoSQL Databases</li>
            <li className="">In memory databases</li>
            <li className="">Writing and reviewing Technical Specifications (such as RFCs)</li>
            <li className="">Writing and reviewing ADR</li>
            <li className="">Popular AWS Solutions</li>
            <li className="">AWS CloudFront</li>
          </ul>
          <h3 className="">Some Experience</h3>
          <ul className="">
            <li className="">CircleCI</li>
            <li className="">Apache Kafka</li>
            <li className="">Spring Boot</li>
            <li className="">Chef</li>
            <li className="">Terraform</li>
            <li className="">Kubernetes</li>
            <li className="">Netlify</li>
            <li className="">Jenkins</li>
            <li className="">Jenkins Job DSL</li>
            <li className="">GraphQL</li>
          </ul>
        </div>
        <div className="interests">
          <h1>Interests + projects</h1>
          <ul className="interests">
            <li>
              Where I have the time, I actively pursue bettering my skills in and out
              of work with side projects, and reading about how other engineers
              approach similar problems with different technologies, to give me a more
              balanced and well-rounded viewpoint
            </li>
            <li>
              Building side projects and writing blog posts
            </li>
            <li>Attending tech meetups, conferences</li>
            <li>Organizing meetups</li>
            <li>I'm going to run my first half marathon this year(2024) excite about that, training 3 times a week</li>
            <li className="">
              I really enjoy sharing knowledge, and improving my communication skills,
              and as well as meeting new people and learning from them at meetups, I have a blog,{" "}
              <a href="https://dev.to/____marcell">https://dev.to/____marcell</a>, which I use to blog as a
              and youtube channel { " "} <a href="https://www.youtube.com/@marcellmonteirocruz2475/videos">https://www.youtube.com/@marcellmonteirocruz2475/videos</a> I'm not very active there but I'm trying to change that recently
            </li>
            <li className="">Knowledge sharing - blogging</li>
            <li>Free and Open Source</li>
            <li>Automation</li>
            <li>Open Standards</li>
            <li>Remote/flexible and async working</li>
          </ul>
        </div>
      </article>
    </div>
  );
};

export default Resume;
